import { Datapoints, ScoreDatapoints } from "types/cube";

interface DatapointsAffixes {
  [key: string]: {
    prefix: "$" | "";
    postfix: "%" | "";
    toString(val: string | number): string;
  };
}

const datapointAffixes: DatapointsAffixes = {
  [Datapoints.HOME_VALUE]: {
    prefix: "$",
    postfix: "",
    toString,
  },
  [Datapoints.HOME_VALUE_GROWTH_RATE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.HOME_VALUE_TO_EARNINGS_RATIO]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.OVER_UNDER_VALUED_PERCENTAGE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.TYPICAL_HOUSE_PAYMENT]: {
    prefix: "$",
    postfix: "",
    toString,
  },
  [Datapoints.SALARY_TO_AFFORD_HOUSE]: {
    prefix: "$",
    postfix: "",
    toString,
  },
  [Datapoints.HOUSE_PAYMENT_RATE_OF_MED_INCOME]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.PROPERTY_TAX_RATE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.BUY_VERSUS_RENT_PERCENTAGE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.PER_CHANGE_HOME_VALUE_JUNE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.PERCENT_CRASH_GREAT_RECESSION]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.MOM_HOME_VALUE_GROWTH]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.TOTAL_ACTIVE_INVENTORY]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.YOY_INVENTORY_CHANGE_PERCENTAGE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.SHARE_OF_LISTINGS_WITH_PRICE_CUT]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.DAYS_ON_MARKET]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.YOY_DAYS_ON_MARKET]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.INVENTORY_PERCENTAGE_OF_HOUSES]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.MOM_ACTIVE_INVENTORY_CHANGE_PERCENTAGE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.MEDIAN_LISTING_PRICE]: {
    prefix: "$",
    postfix: "",
    toString,
  },
  [Datapoints.MEDIAN_LISTING_PRICE_YOY]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.NEW_LISTING_COUNT]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.NEW_LISTING_COUNT_YOY]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.FOR_SALE_INVENTORY_V_AVG]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.POPULATION]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.MEDIAN_HOUSEHOLD_INCOME]: {
    prefix: "$",
    postfix: "",
    toString,
  },
  [Datapoints.POPULATION_GROWTH_RATE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.MEDIAN_INCOME_GROWTH_RATE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.HOME_OWNERSHIP_RATE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.MORTGAGED_HOME_PERCENTAGE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.MEDIAN_AGE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.POVERTY_PERCENTAGE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.HOUSING_UNITS]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.HOUSING_UNIT_GROWTH]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.RENT_FOR_APARTMENTS]: {
    prefix: "$",
    postfix: "",
    toString,
  },
  [Datapoints.RENT_FOR_HOUSES]: {
    prefix: "$",
    postfix: "",
    toString,
  },
  [Datapoints.CAP_RATE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.RENT_PERCENTAGE_OF_INCOME]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.ABSENTEE_OWNER_PERCENTAGE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.TOTAL_MIGRATION]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.MIGRATION_PERCENTAGE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.RENT_GROWTH_YOY]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.HOUSEHOLDS]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.MEDIAN_TAXES]: {
    prefix: "$",
    postfix: "",
    toString,
  },
  [Datapoints.NET_RENT_INCOME]: {
    prefix: "$",
    postfix: "",
    toString,
  },
  [Datapoints.HOUSING_UNITS_WITH_A_MORTGAGE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.OWNERSHIP_HHS]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.ABSENTEE_OWNED_UNITS]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.BUILDING_PERMITS]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.SINGLE_FAMILY_UNITS_PERMITTED]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.MULTI_FAMILY_UNITS_PERMITTED]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_BUILDING_PERMITS]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_SINGLE_PERMITS]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_MULTI_PERMITS]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.BUILDING_PERMIT_PERCENTAGE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.MORTGAGE_RATE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.LISTINGS_WITH_PRICE_CUT]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.EMPLOYMENT_LEVEL]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.UNEMPLOYMENT_RATE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.UNEMPLOYMENT_LEVEL]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.TOTAL_NONFARM_PAYROLL]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.TOTAL_PRIVATE_HOURLY_EARNINGS]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.TOTAL_PRIVATE_WEEKLY_HOURS]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_WEEKLY_HOURS]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_HOURLY_EARNINGS]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.TRAILING_WEEKLY_EARNINGS]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.HOME_BUYER_SCORE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.LONG_TERM_APPRECIATION_PERCENTILE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.WEALTH_INCOME_PERCENTILE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.DEMOGRAPHIC_GROWTH_PERCENTILE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.RECENT_APPRECIATION_SCORE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.DAYS_ON_MARKET_SCORE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.MORTGAGE_RATES_SCORE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.INVENTORY_LEVELS_SCORE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.PRICE_CUTS_SCORE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.INVESTOR_SCORE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.RENT_PERCENTILE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.CAP_RATE_PERCENTILE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [ScoreDatapoints.OVERVALUATION_PERCENTILE]: {
    prefix: "",
    postfix: "",
    toString,
  },
  [Datapoints.VACANCY_RATE]: {
    prefix: "",
    postfix: "%",
    toString,
  },
  [Datapoints.VACANT_HOUSE]: {
    prefix: "",
    postfix: "",
    toString,
  },
};

function toString(
  this: { prefix: string; postfix: string },
  value: string | number,
) {
  return `${this.prefix}${value}${this.postfix}`;
}

export default datapointAffixes;
