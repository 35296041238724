import { getFlooredFixed } from "lib/helpers";
import { Datapoints, ScoreDatapoints } from "types/cube";

const formatter = (value: number, config: Intl.NumberFormatOptions) => {
  const maximumFractionDigits = config.maximumFractionDigits;
  delete config.maximumFractionDigits;
  const formattedValue = value.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
    ...config,
  });
  return getFlooredFixed(formattedValue, maximumFractionDigits);
};

const formatterWithCurrencySign =
  (fractionDigits, notation?) =>
  (value: number, decimals?: number, notationImportant?: string) => {
    return formatter(value, {
      maximumFractionDigits: decimals ? 0 : fractionDigits,
      style: "currency",
      currency: "USD",
      notation: notationImportant || notation || "standard",
    });
  };

const percentageFormatter =
  (fractionDigits) => (value: number, decimals?: number) => {
    return `${formatter(value, {
      maximumFractionDigits: decimals || fractionDigits,
    })}%`;
  };

const normalFormatter =
  (fractionDigits = 0, notation?) =>
  (value, decimals?: number, notationImportant?: string) => {
    return formatter(value, {
      maximumFractionDigits: decimals ? 0 : fractionDigits,
      notation: notationImportant || notation || "standard",
    });
  };

type FormatterMap = {
  [x: string]: {
    primaryFormatter: (
      value: number,
      decimals?: number,
      notation?: string,
    ) => string;
    secondaryFormatter?: (value: number) => string;
  };
};
export enum FormatType {
  Currency = "currency",
  Percentage = "percentage",
  Normal = "normal",
}

export const formatterMap = {
  [Datapoints.HOME_VALUE]: {
    primaryFormatter: formatterWithCurrencySign(1, "compact"),
    secondaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.HOME_VALUE_GROWTH_RATE]: {
    primaryFormatter: percentageFormatter(1),
    secondaryFormatter: percentageFormatter(0),
  },
  [Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE]: {
    primaryFormatter: percentageFormatter(1),
    secondaryFormatter: percentageFormatter(0),
  },
  [Datapoints.INVENTORY_PERCENTAGE_OF_HOUSES]: {
    primaryFormatter: percentageFormatter(1),
    secondaryFormatter: percentageFormatter(0),
  },
  [Datapoints.TOTAL_ACTIVE_INVENTORY]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.ABSENTEE_OWNED_PLUS_OWNERSHIP_HHS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.PROPERTY_TAX_RATE]: {
    primaryFormatter: percentageFormatter(2),
  },
  [Datapoints.YOY_INVENTORY_CHANGE_PERCENTAGE]: {
    primaryFormatter: percentageFormatter(1),
    secondaryFormatter: percentageFormatter(0),
  },
  [Datapoints.DAYS_ON_MARKET]: {
    primaryFormatter: normalFormatter(),
  },
  [Datapoints.YOY_DAYS_ON_MARKET]: {
    primaryFormatter: percentageFormatter(1),
    secondaryFormatter: percentageFormatter(0),
  },
  [Datapoints.MOM_ACTIVE_INVENTORY_CHANGE_PERCENTAGE]: {
    primaryFormatter: percentageFormatter(1),
  },
  [Datapoints.POPULATION]: {
    primaryFormatter: normalFormatter(1, "compact"),
    secondaryFormatter: normalFormatter(0),
  },
  [Datapoints.MEDIAN_HOUSEHOLD_INCOME]: {
    primaryFormatter: formatterWithCurrencySign(1, "compact"),
    secondaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.POPULATION_GROWTH_RATE]: {
    primaryFormatter: percentageFormatter(1),
  },
  [Datapoints.MEDIAN_INCOME_GROWTH_RATE]: {
    primaryFormatter: percentageFormatter(1),
  },
  [Datapoints.HOME_OWNERSHIP_RATE]: {
    primaryFormatter: percentageFormatter(1),
  },
  [Datapoints.OWNERSHIP_HHS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.HOUSEHOLDS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.MORTGAGED_HOME_PERCENTAGE]: {
    primaryFormatter: percentageFormatter(2),
  },
  [Datapoints.HOUSING_UNITS_WITH_A_MORTGAGE]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.MEDIAN_AGE]: {
    primaryFormatter: normalFormatter(1),
  },
  [Datapoints.POVERTY_PERCENTAGE]: {
    primaryFormatter: percentageFormatter(1),
  },
  [Datapoints.POPULATION_LIVING_IN_POVERTY]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.CPI_YOY_PERCENTAGE]: {
    primaryFormatter: normalFormatter(1),
  },

  [Datapoints.MONTHLY_TREASURY_AVG_YIELD]: {
    primaryFormatter: normalFormatter(1),
  },
  [Datapoints.HOUSING_UNITS]: {
    primaryFormatter: normalFormatter(1, "compact"),
    secondaryFormatter: normalFormatter(0),
  },
  [Datapoints.HOUSING_UNIT_GROWTH]: {
    primaryFormatter: percentageFormatter(2),
  },
  [Datapoints.HOME_VALUE_TO_EARNINGS_RATIO]: {
    primaryFormatter: normalFormatter(2),
  },
  [Datapoints.OVER_UNDER_VALUED_PERCENTAGE]: {
    primaryFormatter: percentageFormatter(1),
    secondaryFormatter: percentageFormatter(0),
  },
  [Datapoints.FAIR_HOME_VALUE]: {
    primaryFormatter: formatterWithCurrencySign(1,"compact"),
  },
  [Datapoints.TYPICAL_HOUSE_PAYMENT]: {
    primaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.SALARY_TO_AFFORD_HOUSE]: {
    primaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.PRINCIPLE_INTEREST]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.INSURANCE_TAXES_MISC_COSTS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.MORTGAGE_RATE]: {
    primaryFormatter: normalFormatter(2),
  },
  [Datapoints.HOUSE_PAYMENT_RATE_OF_MED_INCOME]: {
    primaryFormatter: percentageFormatter(2),
    secondaryFormatter: percentageFormatter(0),
  },
  [Datapoints.TYPICAL_YEARLY_HOUSE_PAYMENT]: {
    primaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.PERCENT_CRASH_GREAT_RECESSION]: {
    primaryFormatter: formatterWithCurrencySign(1, "compact"),
    secondaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.MOM_HOME_VALUE_GROWTH]: {
    primaryFormatter: percentageFormatter(2),
  },
  [Datapoints.SHARE_OF_LISTINGS_WITH_PRICE_CUT]: {
    primaryFormatter: percentageFormatter(1),
    secondaryFormatter: percentageFormatter(0),
  },
  [Datapoints.LISTINGS_WITH_PRICE_CUT]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.TOTAL_INVENTORY]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.BUY_VERSUS_RENT_PERCENTAGE]: {
    primaryFormatter: percentageFormatter(2),
  },
  [Datapoints.YEARLY_APARTMENT_RENT]: {
    primaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.ABSENTEE_OWNER_PERCENTAGE]: {
    primaryFormatter: percentageFormatter(2),
  },
  [Datapoints.ABSENTEE_OWNED_UNITS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.RENT_FOR_APARTMENTS]: {
    primaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.RENT_FOR_HOUSES]: {
    primaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.RENT_PERCENTAGE_OF_INCOME]: {
    primaryFormatter: percentageFormatter(2),
  },
  [Datapoints.CAP_RATE]: {
    primaryFormatter: percentageFormatter(1),
  },
  [Datapoints.GROSS_RENT_INCOME]: {
    primaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.EXPENSES]: {
    primaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.NET_RENT_INCOME]: {
    primaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.TOTAL_MIGRATION]: {
    primaryFormatter: normalFormatter(1, "compact"),
  },
  [Datapoints.MIGRATION_PERCENTAGE]: {
    primaryFormatter: percentageFormatter(1),
  },
  [Datapoints.MIGRATION_POPULATION]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.RENT_GROWTH_YOY]: {
    primaryFormatter: percentageFormatter(2),
  },
  [Datapoints.PER_CHANGE_HOME_VALUE_JUNE]: {
    primaryFormatter: formatterWithCurrencySign(1, "compact"),
    secondaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.MEDIAN_LISTING_PRICE]: {
    primaryFormatter: formatterWithCurrencySign(0),
  },
  [Datapoints.MEDIAN_LISTING_PRICE_YOY]: {
    primaryFormatter: percentageFormatter(2),
  },
  [Datapoints.NEW_LISTING_COUNT]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.NEW_LISTING_COUNT_YOY]: {
    primaryFormatter: percentageFormatter(2),
  },
  [Datapoints.FOR_SALE_INVENTORY_V_AVG]: {
    primaryFormatter: percentageFormatter(1),
  },
  [Datapoints.BUILDING_PERMITS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.SINGLE_FAMILY_UNITS_PERMITTED]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.MULTI_FAMILY_UNITS_PERMITTED]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_BUILDING_PERMITS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_SINGLE_PERMITS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_MULTI_PERMITS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.BUILDING_PERMIT_PERCENTAGE]: {
    primaryFormatter: percentageFormatter(2),
  },
  [Datapoints.LONG_TERM_FSI_AVG]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.EMPLOYMENT_LEVEL]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.UNEMPLOYMENT_RATE]: {
    primaryFormatter: percentageFormatter(1),
    secondaryFormatter: percentageFormatter(0),
  },
  [Datapoints.UNEMPLOYMENT_LEVEL]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.TOTAL_NONFARM_PAYROLL]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.TOTAL_PRIVATE_HOURLY_EARNINGS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.TOTAL_PRIVATE_WEEKLY_HOURS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_WEEKLY_HOURS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_HOURLY_EARNINGS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.TRAILING_WEEKLY_EARNINGS]: {
    primaryFormatter: normalFormatter(0),
  },
  [Datapoints.VACANCY_RATE]: {
    primaryFormatter: percentageFormatter(2),
  },
  [Datapoints.VACANT_HOUSE]: {
    primaryFormatter: normalFormatter(5),
  },

  [ScoreDatapoints.RECENT_APPRECIATION_PERCENTAGE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.RECENT_APPRECIATION_SCORE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.INVENTORY_LEVELS_PERCENTAGE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.INVENTORY_LEVELS_SCORE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.PRICE_CUTS_PERCENTAGE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.PRICE_CUTS_SCORE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.DAYS_ON_MARKET_PERCENTAGE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.DAYS_ON_MARKET_SCORE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.MORTGAGE_RATES_YOY_PERCENTAGE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.MORTGAGE_RATES_FIVE_YEAR_PERCENTAGE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.MORTGAGE_RATES_SCORE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.HOME_BUYER_SCORE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.LONG_TERM_APPRECIATION_PERCENTILE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.RENT_PERCENTILE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.CAP_RATE_PERCENTILE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.WEALTH_INCOME_PERCENTILE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.DEMOGRAPHIC_GROWTH_PERCENTILE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.INVESTOR_SCORE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.OVERVALUATION_PERCENTILE]: {
    primaryFormatter: normalFormatter(0),
  },
  [ScoreDatapoints.VALUE_INCOME_RATIO_PERCENTILE]: {
    primaryFormatter: normalFormatter(0),
  },
} as FormatterMap;

export const formatterMapType = {
  [Datapoints.HOME_VALUE]: {
    primaryFormatter: FormatType.Currency,
    secondaryFormatter: FormatType.Currency,
  },
  [Datapoints.HOME_VALUE_GROWTH_RATE]: {
    primaryFormatter: FormatType.Percentage,
    secondaryFormatter: FormatType.Percentage,
  },
  [Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE]: {
    primaryFormatter: FormatType.Percentage,
    secondaryFormatter: FormatType.Percentage,
  },
  [Datapoints.INVENTORY_PERCENTAGE_OF_HOUSES]: {
    primaryFormatter: FormatType.Percentage,
    secondaryFormatter: FormatType.Percentage,
  },
  [Datapoints.TOTAL_ACTIVE_INVENTORY]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.ABSENTEE_OWNED_PLUS_OWNERSHIP_HHS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.PROPERTY_TAX_RATE]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.YOY_INVENTORY_CHANGE_PERCENTAGE]: {
    primaryFormatter: FormatType.Percentage,
    secondaryFormatter: FormatType.Percentage,
  },
  [Datapoints.DAYS_ON_MARKET]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.YOY_DAYS_ON_MARKET]: {
    primaryFormatter: FormatType.Percentage,
    secondaryFormatter: FormatType.Percentage,
  },
  [Datapoints.MOM_ACTIVE_INVENTORY_CHANGE_PERCENTAGE]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.POPULATION]: {
    primaryFormatter: FormatType.Normal,
    secondaryFormatter: FormatType.Normal,
  },
  [Datapoints.MEDIAN_HOUSEHOLD_INCOME]: {
    primaryFormatter: FormatType.Currency,
    secondaryFormatter: FormatType.Currency,
  },
  [Datapoints.POPULATION_GROWTH_RATE]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.MEDIAN_INCOME_GROWTH_RATE]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.HOME_OWNERSHIP_RATE]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.OWNERSHIP_HHS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.HOUSEHOLDS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.MORTGAGED_HOME_PERCENTAGE]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.HOUSING_UNITS_WITH_A_MORTGAGE]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.MEDIAN_AGE]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.POVERTY_PERCENTAGE]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.POPULATION_LIVING_IN_POVERTY]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.HOUSING_UNITS]: {
    primaryFormatter: FormatType.Normal,
    secondaryFormatter: FormatType.Normal,
  },
  [Datapoints.HOUSING_UNIT_GROWTH]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.HOME_VALUE_TO_EARNINGS_RATIO]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.OVER_UNDER_VALUED_PERCENTAGE]: {
    primaryFormatter: FormatType.Percentage,
    secondaryFormatter: FormatType.Percentage,
  },
  [Datapoints.FAIR_HOME_VALUE]: {
    primaryFormatter: FormatType.Currency,
  },
  [Datapoints.CPI_YOY_PERCENTAGE]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.MONTHLY_TREASURY_AVG_YIELD]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.TYPICAL_HOUSE_PAYMENT]: {
    primaryFormatter: FormatType.Currency,
  },
  [Datapoints.SALARY_TO_AFFORD_HOUSE]: {
    primaryFormatter: FormatType.Currency,
  },
  [Datapoints.PRINCIPLE_INTEREST]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.INSURANCE_TAXES_MISC_COSTS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.MORTGAGE_RATE]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.HOUSE_PAYMENT_RATE_OF_MED_INCOME]: {
    primaryFormatter: FormatType.Percentage,
    secondaryFormatter: FormatType.Percentage,
  },
  [Datapoints.TYPICAL_YEARLY_HOUSE_PAYMENT]: {
    primaryFormatter: FormatType.Currency,
  },
  [Datapoints.PERCENT_CRASH_GREAT_RECESSION]: {
    primaryFormatter: FormatType.Currency,
    secondaryFormatter: FormatType.Currency,
  },
  [Datapoints.MOM_HOME_VALUE_GROWTH]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.SHARE_OF_LISTINGS_WITH_PRICE_CUT]: {
    primaryFormatter: FormatType.Percentage,
    secondaryFormatter: FormatType.Percentage,
  },
  [Datapoints.LISTINGS_WITH_PRICE_CUT]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.TOTAL_INVENTORY]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.BUY_VERSUS_RENT_PERCENTAGE]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.YEARLY_APARTMENT_RENT]: {
    primaryFormatter: FormatType.Currency,
  },
  [Datapoints.ABSENTEE_OWNER_PERCENTAGE]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.ABSENTEE_OWNED_UNITS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.RENT_FOR_APARTMENTS]: {
    primaryFormatter: FormatType.Currency,
  },
  [Datapoints.RENT_FOR_HOUSES]: {
    primaryFormatter: FormatType.Currency,
  },
  [Datapoints.RENT_PERCENTAGE_OF_INCOME]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.CAP_RATE]: {
    primaryFormatter: FormatType.Percentage,
    secondaryFormatter: FormatType.Percentage,
  },
  [Datapoints.GROSS_RENT_INCOME]: {
    primaryFormatter: FormatType.Currency,
  },
  [Datapoints.EXPENSES]: {
    primaryFormatter: FormatType.Currency,
  },
  [Datapoints.NET_RENT_INCOME]: {
    primaryFormatter: FormatType.Currency,
  },
  [Datapoints.TOTAL_MIGRATION]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.MIGRATION_PERCENTAGE]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.MIGRATION_POPULATION]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.RENT_GROWTH_YOY]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.PER_CHANGE_HOME_VALUE_JUNE]: {
    primaryFormatter: FormatType.Currency,
    secondaryFormatter: FormatType.Currency,
  },
  [Datapoints.MEDIAN_LISTING_PRICE]: {
    primaryFormatter: FormatType.Currency,
  },
  [Datapoints.MEDIAN_LISTING_PRICE_YOY]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.NEW_LISTING_COUNT]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.NEW_LISTING_COUNT_YOY]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.FOR_SALE_INVENTORY_V_AVG]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.BUILDING_PERMITS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.SINGLE_FAMILY_UNITS_PERMITTED]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.MULTI_FAMILY_UNITS_PERMITTED]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_BUILDING_PERMITS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_SINGLE_PERMITS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_MULTI_PERMITS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.BUILDING_PERMIT_PERCENTAGE]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.LONG_TERM_FSI_AVG]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.EMPLOYMENT_LEVEL]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.UNEMPLOYMENT_RATE]: {
    primaryFormatter: FormatType.Percentage,
    secondaryFormatter: FormatType.Percentage,
  },
  [Datapoints.UNEMPLOYMENT_LEVEL]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.TOTAL_NONFARM_PAYROLL]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.TOTAL_PRIVATE_HOURLY_EARNINGS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.TOTAL_PRIVATE_WEEKLY_HOURS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_WEEKLY_HOURS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_HOURLY_EARNINGS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.TRAILING_WEEKLY_EARNINGS]: {
    primaryFormatter: FormatType.Normal,
  },
  [Datapoints.VACANCY_RATE]: {
    primaryFormatter: FormatType.Percentage,
  },
  [Datapoints.VACANT_HOUSE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.RECENT_APPRECIATION_PERCENTAGE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.RECENT_APPRECIATION_SCORE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.INVENTORY_LEVELS_PERCENTAGE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.INVENTORY_LEVELS_SCORE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.PRICE_CUTS_PERCENTAGE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.PRICE_CUTS_SCORE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.DAYS_ON_MARKET_PERCENTAGE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.DAYS_ON_MARKET_SCORE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.MORTGAGE_RATES_YOY_PERCENTAGE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.MORTGAGE_RATES_FIVE_YEAR_PERCENTAGE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.MORTGAGE_RATES_SCORE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.HOME_BUYER_SCORE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.LONG_TERM_APPRECIATION_PERCENTILE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.RENT_PERCENTILE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.CAP_RATE_PERCENTILE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.WEALTH_INCOME_PERCENTILE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.DEMOGRAPHIC_GROWTH_PERCENTILE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.INVESTOR_SCORE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.OVERVALUATION_PERCENTILE]: {
    primaryFormatter: FormatType.Normal,
  },
  [ScoreDatapoints.VALUE_INCOME_RATIO_PERCENTILE]: {
    primaryFormatter: FormatType.Normal,
  },
};
