import { ScoreDatapoints } from "types/cube";
import datapointAffixes from "./options/datapointAffixes";
import { exploratoryMap } from "./options/exploratoryMap";

export const getFixedForExploratory = (exploratory = "") => {
  const sanitizedExploratory = exploratory.toString();
  // rouund off to one decimal
  if (
    sanitizedExploratory === "trailing_twelve_months_multi_permits" ||
    sanitizedExploratory === "trailing_twelve_months_single_permits" ||
    sanitizedExploratory === "trailing_twelve_months_building_permits" ||
    sanitizedExploratory === "new_listing_count" ||
    sanitizedExploratory === "median_listing_price" ||
    sanitizedExploratory === "mom_home_value_growth"
  ) {
    return 2;
  }

  if (
    sanitizedExploratory.includes("millenial") ||
    sanitizedExploratory.includes("senior") ||
    sanitizedExploratory.includes("poverty") ||
    sanitizedExploratory.includes("share_of_listings_with_price_cut") ||
    sanitizedExploratory.includes("yoy_inventory_change_percentage") ||
    sanitizedExploratory.includes("median_age") ||
    sanitizedExploratory.includes("average_weekly_hours_worked") ||
    sanitizedExploratory.includes("median_income_growth_rate") ||
    sanitizedExploratory.includes("wage_growth_rate") ||
    sanitizedExploratory.includes("population_growth_rate") ||
    sanitizedExploratory.includes("rental_rate_growth") ||
    sanitizedExploratory.includes("home_ownership_rate") ||
    sanitizedExploratory.includes("yoy_price_reduced_percentage") ||
    sanitizedExploratory.includes("mom_inventory_change_percentage") ||
    sanitizedExploratory.includes("mom_active_inventory_change_percentage") ||
    sanitizedExploratory.includes("inventory_percentage") ||
    sanitizedExploratory.includes("rental_yield_rate") ||
    sanitizedExploratory.includes("growth_rate") ||
    sanitizedExploratory.includes("cap_rate") ||
    sanitizedExploratory.includes("vacant_percentage") ||
    sanitizedExploratory.includes("over_under_valued_percentage") ||
    sanitizedExploratory.includes("percent_crash_great_recession") ||
    sanitizedExploratory.includes("yoy_days_on_market") ||
    sanitizedExploratory === "per_change_home_value_june" ||
    sanitizedExploratory === "for_sale_inventory_v_avg"
  ) {
    return 1;
  }
  if (
    (sanitizedExploratory.includes("population") ||
      sanitizedExploratory.includes("median_household_income") ||
      sanitizedExploratory.includes("B14006_001E") ||
      sanitizedExploratory.includes("home_value") ||
      sanitizedExploratory.includes("B11001_001E") ||
      sanitizedExploratory.includes("B08537_019E") ||
      sanitizedExploratory.includes("total_jobs") ||
      sanitizedExploratory.includes("annual_earnings") ||
      sanitizedExploratory.includes("B25077_001E") ||
      sanitizedExploratory.includes("housing_units") ||
      sanitizedExploratory.includes("total_permits") ||
      sanitizedExploratory.includes("single_family_permits") ||
      sanitizedExploratory.includes("multi_family_permits") ||
      sanitizedExploratory.includes("total_active_inventory") ||
      sanitizedExploratory.includes("listings_with_price_cut") ||
      sanitizedExploratory.includes("typical_rent") ||
      sanitizedExploratory.includes("median_taxes") ||
      sanitizedExploratory.includes("net_rent_income") ||
      sanitizedExploratory.includes("sfh_") ||
      sanitizedExploratory.includes("housing_units_with_a_mortgage") ||
      sanitizedExploratory.includes("owner_occupied_units") ||
      sanitizedExploratory.includes("absentee_owned_units") ||
      sanitizedExploratory.includes("vacant_units") ||
      sanitizedExploratory.includes("tech_workers") ||
      sanitizedExploratory.includes("total_workers") ||
      sanitizedExploratory.includes("remote_workers") ||
      sanitizedExploratory.includes("total_housing_units_in_structure") ||
      sanitizedExploratory.includes("typical_house_payment") ||
      sanitizedExploratory.includes("salary_to_afford_house") ||
      sanitizedExploratory.includes("total_migration") ||
      sanitizedExploratory.includes("rent_for_apartments") ||
      sanitizedExploratory.includes("rent_for_houses") ||
      sanitizedExploratory.includes("days_on_market") ||
      sanitizedExploratory === ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE ||
      sanitizedExploratory === ScoreDatapoints.HOME_BUYER_SCORE ||
      sanitizedExploratory === ScoreDatapoints.INVESTOR_SCORE) &&
    sanitizedExploratory !== "mom_home_value_growth" &&
    sanitizedExploratory !== "home_value_to_earnings_ratio"
  ) {
    return 0;
  }

  return 1;
};

export const formatRootValue = ({ exploratory, value }) => {
  return getFlooredFixed(parseFloat(value), getFixedForExploratory(exploratory))
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getMapDisplayValue = ({ exploratory, value }) => {
  return datapointAffixes[exploratory]?.toString(
    formatRootValue({
      exploratory,
      value,
    }),
  );
};

export const getCodesToFilter = (selectedFilters) => {
  return selectedFilters.reduce(function (acc, selectedFilter) {
    let filterCode;
    for (var key in exploratoryMap) {
      const obj = exploratoryMap[key];
      if (obj.filterName === selectedFilter.label) {
        filterCode = obj.code;
      }
    }
    if (selectedFilter.label === "state_code") {
      filterCode = "state_code";
    }
    if (
      filterCode &&
      selectedFilter.val.length > 0 &&
      selectedFilter.val.length < 5
    ) {
      return acc.concat({
        filterCode,
        selectedFilter,
      });
    }

    return acc;
  }, []);
};

export const sanitizeGeoName = (value) => {
  const abbreviatedName = value?.split("-");

  if (abbreviatedName && abbreviatedName[0]) {
    return abbreviatedName[0];
  } else {
    return value;
  }
};

/**
 *
 * @param {any} stateData
 * @returns {GeoJSON.FeatureCollection<GeoJSON.Geometry>}
 */
export const sanitizedStateOutlineGeoJson = (stateData) => {
  const uniqueStates =
    stateData?.census?.features?.filter((value, index, self) => {
      return (
        self.findIndex(
          (v) =>
            v.properties.GEOID === value.properties.GEOID &&
            value.properties.GEOID !== "72" &&
            v.properties.GEOID === value.properties.GEOID &&
            value.properties.GEOID !== "11",
        ) === index
      );
    }) || [];

  return {
    features: uniqueStates,
    type: "FeatureCollection",
  };
};

export const getSourceLink = (source = "") => {
  const sourceStr = source.toLowerCase();
  if (sourceStr.includes("zillow")) {
    return "https://www.zillow.com/research/data/";
  }

  if (sourceStr.includes("realtor")) {
    return "https://www.realtor.com/research/data/";
  }

  if (sourceStr.includes("census")) {
    return "https://www.census.gov/programs-surveys/acs";
  }

  if (sourceStr.includes("bls")) {
    return "https://www.bls.gov/";
  }
};

export const sanitizeFeatureValue = (value, backUpValue = 0) => {
  if (isFinite(value) && !isNaN(value) && value !== -666666666) {
    // Math.sign(value) === -1 ? backUpValue :
    return Number(value);
  } else {
    return backUpValue;
  }
};

/**
 *
 * @param {string} str
 * @returns {string}
 */
export const slugify = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-z0-9]+/gi, "-")
    .replace(/(^-+|-+$)/g, "");
};

export const formatDate = (day, useShortname = false) => {
  const m = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const sm = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const str_op =
    (useShortname ? sm : m)[day.getMonth()] +
    " " +
    day.getDate() +
    ", " +
    day.getFullYear();
  return str_op;
};

export const truncateString = (str, num) => {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};

export const getFlooredFixed = (value, decimalPlaces, textFormat) => {
  const suffix = `${value}`.replace(/[^a-zA-Z]/g, "");
  const str = `${value}`.replace(suffix, "");
  if (str.includes(".")) {
    return `${str.slice(
      0,
      str.indexOf(".") + (decimalPlaces > 0 ? decimalPlaces + 1 : 0),
    )}${suffix}`;
  } else if (decimalPlaces === 1 && !`${value}`.includes("$")) {
    return Number(str).toFixed(1);
  } else if (textFormat === "%") {
    return Number(str).toFixed(1);
  } else {
    return `${str}${suffix}`;
  }
};
